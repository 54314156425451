import React, { useState } from 'react';

import { ApplicationSpinner } from '@zawarski/palmetto-ui-components';

export const Loading = (props) => {
  const [text, setText] = useState(props.text || 'Loading projects, please wait...');

  return (
    <div className='layout vertical vertical-center full-height'>
      <ApplicationSpinner />
      <div className='height-20'></div>
      <span style={{ padding: '0 16px' }} className='text-center title-big opacity-54'>
        {text}
      </span>
    </div>
  );
};
