import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

/**
 *
 * @param {Array} arr array of byte values
 * @returns {Object} payload from python script
 */
export default function decodeBarcode(str) {
  // Remove non-ascii characters
  let ascii = cleanStr(str);

  const toRet = {};

  let regex =
    /^ITS[A-Za-z]+([0-9]+)[A-Za-z]+"([A-Za-z]+)\*,([A-Z-a-z ]+)[0-9a-z]+"([A-Za-z]+)\*([A-Za-z]+)/;

  let m = ascii.match(regex);

  // 0 -> whole string, rest -> each capturing group

  if (m.length === 6) {
    toRet.pvBadgeID = m[1];
    toRet.pvBadgeGroupTitle = m[2];
    toRet.pvBadgeGroupName = m[3];
    toRet.pvBadgeLastName = m[4];
    toRet.pvBadgeFirstName = m[5].replace(/z$/, '');
  }

  // toRet.pvScannerName = "_phone";
  toRet.pvGlobalID = uuidv4();
  toRet.pvCreateDate = moment().utc().format('YYYY-MM-DD HH:mm:ss.SSS');

  return toRet;
}

function cleanStr(str) {
  return str.replace(/[^\u0020-\u007e]+/g, '').trim();
}
