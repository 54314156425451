import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

function CustomIcon(props) {
  return (
    <div style={{ position: 'relative' }}>
      <SvgIcon {...props}>
        <path d='M14.08 15.59L16.67 13H7V11H16.67L14.08 8.41L15.5 7L20.5 12L15.5 17L14.08 15.59ZM19 3C19.5304 3 20.0391 3.21071 20.4142 3.58579C20.7893 3.96086 21 4.46957 21 5V9.67L19 7.67V5H5V19H19V16.33L21 14.33V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V5C3 3.89 3.89 3 5 3H19Z' />
      </SvgIcon>
    </div>
  );
}

export default CustomIcon;
