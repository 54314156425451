/**
 * Contains actions related to printing and generating documents
 */

import { apiClient } from '../../common/axios_instance';
const ENDPOINT = process.env.REACT_APP_SERVERLESS_DOCUMENT_API_ENDPOINT;

const REQUEST_CONFIG = {
  responseType: 'arraybuffer',
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  },
};

/**
 * Generates and Downloads a document from the serverless Documents Endpoint
 * @param {Object} payload parameters for the document
 * @param {Function} callback something to call when the document is downloaded
 */
export function downloadDocument(payload, callback) {
  return async function (dispatch, getState) {
    let resp = null;

    try {
      if (payload.body) {
        resp = await apiClient.post(ENDPOINT + payload.url, payload.body, REQUEST_CONFIG);
      } else {
        resp = await apiClient.get(ENDPOINT + payload.url, REQUEST_CONFIG);
      }

      let type = resp.headers['content-type'] || 'application/vnd.ms-excel';
      let filename = 'document';
      if (payload.filename) {
        filename = payload.filename;
      } else if (resp.headers['content-disposition']) {
        filename = getFilenameFromHeader(resp.headers['content-disposition']);
      }
      let blob = new Blob([resp.data], { type: type });
      let url = URL.createObjectURL(blob);
      let a = document.createElement('a');
      a.href = url;
      a.target = '_blank';
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      if (callback) {
        callback();
      }
    } catch (err) {
      console.error('Failed to get Document:', err);
      if (callback) {
        callback(err);
      }
    }
  };
}

// Gets the filename from the content disposition header
function getFilenameFromHeader(contentDisp) {
  // attachment;  filename="ICS_203-Alien Invasion1-0112211159.docx"
  const beginning = 'filename=';
  let toRet = contentDisp
    .substring(contentDisp.indexOf(beginning) + beginning.length)
    .replaceAll('"', '')
    .replaceAll("'", '');
  return toRet;
}

export function printTable(type, payload, callback) {
  return async function (dispatch, getState) {
    let postPayload = payload;
    let url = process.env.REACT_APP_PALMETTO_SERVERLESS_DOCUMENTS_ENDPOINT;
    apiClient
      .post(url + '/documents/' + type + '/print', postPayload, {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        },
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', type + '.xlsx'); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => console.log(error));
    if (callback) {
      callback();
    }
  };
}
