import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

function CustomIcon(props) {
  return (
    <SvgIcon sx={{ position: 'absolute', top: '50%' }} {...props}>
      <path d='M18 6H6C5.46957 6 4.96086 6.21071 4.58579 6.58579C4.21071 6.96086 4 7.46957 4 8V16C4 16.5304 4.21071 17.0391 4.58579 17.4142C4.96086 17.7893 5.46957 18 6 18H18C18.5304 18 19.0391 17.7893 19.4142 17.4142C19.7893 17.0391 20 16.5304 20 16V8C20 7.46957 19.7893 6.96086 19.4142 6.58579C19.0391 6.21071 18.5304 6 18 6ZM18 12H6V9H18M2 4H6V2H2C1.46957 2 0.960859 2.21071 0.585786 2.58579C0.210714 2.96086 0 3.46957 0 4L0 8H2V4ZM22 2H18V4H22V8H24V4C24 3.46957 23.7893 2.96086 23.4142 2.58579C23.0391 2.21071 22.5304 2 22 2ZM2 16H0V20C0 20.5304 0.210714 21.0391 0.585786 21.4142C0.960859 21.7893 1.46957 22 2 22H6V20H2V16ZM22 20H18V22H22C22.5304 22 23.0391 21.7893 23.4142 21.4142C23.7893 21.0391 24 20.5304 24 20V16H22V20Z' />
    </SvgIcon>
  );
}

export default CustomIcon;
