import React, { useState } from 'react';
import {
  RequiredTextField,
  NormalTextField,
  PalmettoDatePicker,
  NormalDropdown,
  RequiredDropdown,
  CustomInputLabel,
} from '@zawarski/palmetto-ui-components';
import moment from 'moment';
import { FormControl, Input, MenuItem } from '@mui/material';
import { NEW_PAGE_DROPDOWN_MENU_PROPS } from '../utilities/Constants';
import '../styles/common.css';

export const NormalTextFieldFunc = (props) => {
  const [value, setValue] = useState(props.value || '');
  const updateValue = (e) => {
    setValue(e.target.value);
    props.callback && props.callback(e.target.value);
  };
  return <NormalTextField {...props} value={value} onChange={(e) => updateValue(e)} />;
};

export const RequiredTextFieldFunc = (props) => {
  const [value, setValue] = useState(props.value || '');
  const updateValue = (e) => {
    setValue(e.target.value);
    props.callback && props.callback(e.target.value);
  };
  return <RequiredTextField {...props} value={value} onChange={(e) => updateValue(e)} />;
};

export const PalmettoDatePickerFunc = (props) => {
  const [date, setDate] = useState(props.value || ' ');
  const { ...other } = props;
  const [datePickerRef, setDatePickerRef] = useState({});

  const onChange = (event) => {
    const value = moment(event).format('YYYY-MM-DD');
    setDate(value);
    props.callback && props.callback(event);
  };

  const DateRef = (ref) => {
    setDatePickerRef(ref);
  };

  return (
    <>
      <PalmettoDatePicker
        {...other}
        value={date}
        refFunc={DateRef}
        onChange={onChange}
        onClose={datePickerRef.closeDatePicker}
      />
    </>
  );
};

export const NormalDropdownFunc = (props) => {
  let field = props.field;
  let values = props.values || [];
  let name = props.name;
  let id = props.id || props.fieldName;
  let required = props.required || false;
  let placeholder = props.placeholder || '';

  const [value, setValue] = useState(props.value || '');

  const updateValue = (e) => {
    setValue(e.target.value);
    props.callback && props.callback(e.target.value);
  };

  return (
    <FormControl className='flex' style={{ minHeight: '40px', minWidth: '250px' }}>
      <CustomInputLabel style={{ margin: '0 6px' }} shrink htmlFor={id}>
        {name}
      </CustomInputLabel>
      <NormalDropdown
        value={value}
        field={field}
        onChange={(e) => updateValue(e)}
        input={<Input name={field} id={id}></Input>}
        displayEmpty
        custommargin='true'
        name={field}
        MenuProps={NEW_PAGE_DROPDOWN_MENU_PROPS}
        className='flex'>
        {placeholder ? (
          <MenuItem value='' disabled>
            <span className='placeholder'>{placeholder}</span>
          </MenuItem>
        ) : (
          ''
        )}
        {values.map((type, index) => {
          if (type && typeof type === 'object') {
            return (
              <MenuItem key={index} value={type.key}>
                {type.value}
              </MenuItem>
            );
          } else {
            return (
              <MenuItem key={index} value={type}>
                {type}
              </MenuItem>
            );
          }
        })}
      </NormalDropdown>
    </FormControl>
  );
};

export const RequiredDropdownFunc = (props) => {
  let field = props.field;
  let values = props.values || [];
  let name = props.name;
  let id = props.id || props.fieldName;
  let required = props.required || false;
  let placeholder = props.placeholder || '';

  const [value, setValue] = useState(props.value || '');

  const updateValue = (e) => {
    setValue(e.target.value);
    props.callback && props.callback(e.target.value);
  };

  return (
    <FormControl
      error={props.error}
      className='flex'
      style={{ minHeight: '40px', minWidth: '250px' }}>
      <CustomInputLabel error={props.error} style={{ margin: '0 6px' }} shrink htmlFor={id}>
        {name} <span className={required ? 'required' : ''}></span>
      </CustomInputLabel>
      <RequiredDropdown
        value={value}
        field={field}
        onChange={(e) => updateValue(e)}
        input={<Input name={field} id={id}></Input>}
        displayEmpty
        custommargin='true'
        name={field}
        placeholder={placeholder}
        MenuProps={NEW_PAGE_DROPDOWN_MENU_PROPS}
        className='flex'>
        {placeholder ? (
          <MenuItem value='' disabled>
            <span className='placeholder'>{placeholder}</span>
          </MenuItem>
        ) : (
          ''
        )}
        {values.map((type, index) => {
          if (type && typeof type === 'object') {
            return (
              <MenuItem key={index} value={type.key}>
                {type.value}
              </MenuItem>
            );
          } else {
            return (
              <MenuItem key={index} value={type}>
                {type}
              </MenuItem>
            );
          }
        })}
      </RequiredDropdown>
    </FormControl>
  );
};
