import React from 'react';
import PalmettoAuthV2 from './components/auth/PalmettoAuthV2';
import { datadogLogs } from '@datadog/browser-logs';
import App from './App';

const AppShell = () => {
  datadogLogs.init({
    clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
    datacenter: 'us',
    env: process.env.REACT_APP_DATADOG_STAGING_ENV,
    forwardErrorsToLogs: false,
    sampleRate: 100,
  });
  return (
    <PalmettoAuthV2>
      <App />
    </PalmettoAuthV2>
  );
};

export default AppShell;
