/* eslint-disable no-unused-vars */
/**
 * This file contains most of the actions used around the application
 */

// import {wsInit, wsJoinRoom} from "../utils/WebsocketAPI";
import { connect, joinRoom } from '../utils/ws';
import { apiClient } from '../../common/axios_instance';
import moment from 'moment';

// @TODO Comment this block out

// const defaultScannerBadge = [{"pvCreateDate":"2020-12-09 13:50:24.315","pvBadgeID":"00617","pvBadgeFirstName":"Phill","pvBadgeGroupName":"South Carolina Emergency Management Division","pvBadgeGroupTitle":"SCEMD","pvScannerName":"scemd_1","pvGlobalID":"adb33d1e-210c-4dcd-93cc-8ad1c784d613"}]

// function addTempListeners(dispatch) {
//     window.addEventListener('temp', () => {
//         dispatch({type: 'CURRENT_SCANNED_BADGE', payload: defaultScannerBadge[0]});
//         // if(window.badgeTimeoutInterval) {
//         //     console.log('resetting')
//         //     window.clearInterval(window.badgeTimeoutInterval);
//         // }

//         // window.badgeTimeoutInterval = setTimeout(function() {
//         //     console.log('clear interval')
//         //     dispatch({type: 'CURRENT_SCANNED_BADGE', payload: {}});
//         // }, 6000)

//     })
// }

// END

/**
 * This sets the default info right after the user logs in
 * @param {Object} payload
 */
export function setDefaultInfo(payload) {
  return async function (dispatch, getState) {
    // palmettoToken.accessToken = await getPalmettoAccessID(palmettoToken.username, palmettoToken.password);

    let creds =
      sessionStorage.getItem('userAuthCreds') &&
      JSON.parse(sessionStorage.getItem('userAuthCreds'));

    creds.accessToken = creds.id;

    dispatch({ type: 'PALMETTO_USER_ACCOUNT', payload: payload.palmettoUserAccount });
    dispatch({ type: 'SET_SELECTED_GROUP', payload: payload.selectedCounty });

    // Get the message setting

    await getGroupSettings(dispatch, payload.selectedCounty);

    // get the scanners

    let updatedScannerName = await getScannersForTheGroup(
      dispatch,
      payload.palmettoUserAccount,
      payload.selectedCounty,
    );

    const room = 'egresseoc-' + updatedScannerName;
    dispatch({ type: 'SELECTED_SCANNER', payload: updatedScannerName });

    let authData = JSON.parse(sessionStorage.getItem('userAuthCreds'));

    await connect(
      payload.palmettoUserAccount.id,
      payload.selectedCounty.pvGroupID,
      payload.palmettoUserAccount.pvAdministrator,
      authData.id,
    );

    await listenToScanner(room, dispatch, getState);
    // @TODO comment this out
    // remove during deployment
    // addTempListeners(dispatch);
    // END
    dispatch({ type: 'APPLICATION_LOADING_STATE', payload: false });
  };
}

async function listenToScanner(room, dispatch, getState) {
  const cb = (message) => {
    const { currentScannedBadge, selectedScanner } = getState().rootReducer;

    // if the message is not intended for the selected scanner, ignore
    if (message && message[0] && message[0].pvScannerName !== selectedScanner) return;

    // if the badge scan is already in progress, ignore the other message

    if (!currentScannedBadge.pvBadgeID) {
      dispatch({ type: 'CURRENT_SCANNED_BADGE', payload: message[0] });
    }
  };

  joinRoom(room, room, cb);
}

async function getGroupSettings(dispatch, group) {
  let palmettoSettingsFilter = {
    where: {
      and: [
        {
          pvSettingType: `${group.pvGroupName.toLowerCase()}-welcome-message`,
        },
        {
          pvGroupID: group.pvGroupID,
        },
      ],
    },
  };
  let authObj = JSON.parse(sessionStorage.getItem('userAuthCreds'));

  let settingsRes = await apiClient.get(
    process.env.REACT_APP_PALMETTO_ENDPOINT +
      '/api/settings?access_token=' +
      authObj.id +
      '&filter=' +
      JSON.stringify(palmettoSettingsFilter),
  );

  if (settingsRes.data && settingsRes.data.length) {
    // Sort by last updated at
    // Added these in case there are duplicate settings
    dispatch({ type: 'WELCOME_MESSAGE_SETTING', payload: settingsRes.data[0] || {} });
  }
}

async function getScannersForTheGroup(dispatch, user, group) {
  try {
    let authObj = JSON.parse(sessionStorage.getItem('userAuthCreds'));

    let scannerRes = await apiClient.get(
      `${
        process.env.REACT_APP_ENTRY_EGRESS_API_ENDPOINT
      }/api/entryegress/scanners/${group.pvGroupName.toLowerCase()}`,
    );

    if (
      scannerRes &&
      scannerRes.data &&
      scannerRes.data.dataset &&
      scannerRes.data.dataset.length
    ) {
      dispatch({ type: 'ALL_SCANNERS', payload: scannerRes.data.dataset });
    } else {
      dispatch({
        type: 'ALL_SCANNERS',
        payload: [{ pvScannerName: `${group.pvGroupName.toLowerCase()}_1` }],
      });

      // return `${group.pvGroupName.toLowerCase()}_1`;
      // `${group.pvGroupName.toLowerCase()}_1`;
    }

    let palmettoSettingsFilter = {
      where: {
        and: [
          {
            pvSettingType: `${group.pvGroupName.toLowerCase()}-user-selected-scanner`,
          },
          {
            pvGroupID: group.pvGroupID,
          },
          {
            pvAccountID: user.id,
          },
        ],
      },
    };

    let settingsRes = await apiClient.get(
      process.env.REACT_APP_PALMETTO_ENDPOINT +
        '/api/settings?access_token=' +
        authObj.id +
        '&filter=' +
        JSON.stringify(palmettoSettingsFilter),
    );

    if (settingsRes.data && settingsRes.data.length) {
      // Sort by last updated at
      // Added these in case there are duplicate settings
      dispatch({ type: 'SCANNER_SETTING', payload: settingsRes.data[0] });
      dispatch({ type: 'SELECTED_SCANNER', payload: settingsRes.data[0].pvSettingValue });

      return settingsRes.data[0].pvSettingValue;
    }

    return `${group.pvGroupName.toLowerCase()}_1`;
  } catch (e) {
    console.error('Error getting scanner information');
  }
}

export function getLatestGroupSettings(callback) {
  return async function (dispatch, getState) {
    const { selectedGroup } = getState().rootReducer;

    await getGroupSettings(dispatch, selectedGroup);
    callback && callback();
  };
}

export function getLatestScanners(callback) {
  return async function (dispatch, getState) {
    const { palmettoUserAccount, selectedGroup } = getState().rootReducer;

    await getScannersForTheGroup(dispatch, palmettoUserAccount, selectedGroup);
    callback && callback();
  };
}

export function processSignIn(pvInfo, callback) {
  return async function (dispatch, getState) {
    dispatch({ type: 'APPLICATION_LOADING_STATE', payload: true });

    const { currentScannedBadge } = getState().rootReducer;

    // add an interval

    if (window.badgeTimeoutInterval) {
      window.clearInterval(window.badgeTimeoutInterval);
    }

    window.badgeTimeoutInterval = setTimeout(function () {
      dispatch({ type: 'CURRENT_SCANNED_BADGE', payload: {} });
    }, 1000);

    // make a request to sign out the user

    let authObj = JSON.parse(sessionStorage.getItem('userAuthCreds'));
    let body = {
      pvGlobalID: currentScannedBadge.pvGlobalID,
      pvStatus: 'SIGN-IN',
      pvInfo,
    };

    await apiClient.post(
      `${process.env.REACT_APP_ENTRY_EGRESS_API_ENDPOINT}/api/entryegress/update?access_token=${authObj.id}`,
      body,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );

    callback();
    dispatch({ type: 'APPLICATION_LOADING_STATE', payload: false });
  };
}

export function processSignOut(pvInfo, callback) {
  return async function (dispatch, getState) {
    const { currentScannedBadge } = getState().rootReducer;

    // add an interval
    dispatch({ type: 'APPLICATION_LOADING_STATE', payload: true });

    if (window.badgeTimeoutInterval) {
      window.clearInterval(window.badgeTimeoutInterval);
    }

    window.badgeTimeoutInterval = setTimeout(function () {
      dispatch({ type: 'CURRENT_SCANNED_BADGE', payload: {} });
    }, 1000);

    // make a request to sign out the user

    let authObj = JSON.parse(sessionStorage.getItem('userAuthCreds'));
    let body = {
      pvGlobalID: currentScannedBadge.pvGlobalID,
      pvStatus: 'SIGN-OUT',
      pvInfo,
    };

    await apiClient.post(
      `${process.env.REACT_APP_ENTRY_EGRESS_API_ENDPOINT}/api/entryegress/update?access_token=${authObj.id}`,
      body,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );

    callback();
    dispatch({ type: 'APPLICATION_LOADING_STATE', payload: false });
  };
}

// Marks a user as "PRESENT" when scanned using the mobile scanner
export function processAttendanceMode(badge, callback) {
  return async function (dispatch, getState) {
    let authObj = JSON.parse(sessionStorage.getItem('userAuthCreds'));
    let body = {
      ...badge,
      pvStatus: 'PRESENT',
    };

    await apiClient.post(
      `${process.env.REACT_APP_ENTRY_EGRESS_API_ENDPOINT}/api/entryegress/create?access_token=${authObj.id}`,
      body,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );

    callback();
  };
}

export function processMobileSignin(payload, callback) {
  return async function (dispatch, getState) {

    if (window.badgeTimeoutInterval) {
      window.clearInterval(window.badgeTimeoutInterval);
    }

    window.badgeTimeoutInterval = setTimeout(function () {
      dispatch({ type: 'CURRENT_SCANNED_BADGE', payload: {} });
    }, 1000);

    let authObj = JSON.parse(sessionStorage.getItem('userAuthCreds'));
    let body = {
      ...payload,
    };

    await apiClient.post(
      `${process.env.REACT_APP_ENTRY_EGRESS_API_ENDPOINT}/api/entryegress/create?access_token=${authObj.id}`,
      body,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );

    callback();
  };
}

export function processNewUser(callback) {
  return async function (dispatch, getState) {
    dispatch({ type: 'CURRENT_SCANNED_BADGE', payload: {} });
    callback && callback();
  };
}

export function saveMessage(payload, callback) {
  return async function (dispatch, getState) {
    const { palmettoUserAccount, selectedGroup, welcomeMessageSetting } = getState().rootReducer;

    try {
      let authObj = JSON.parse(sessionStorage.getItem('userAuthCreds'));

      await apiClient.put(
        process.env.REACT_APP_PALMETTO_ENDPOINT + '/api/settings?access_token=' + authObj.id,
        {
          pvSettingID: welcomeMessageSetting.pvSettingID || undefined,
          pvSettingType: `${selectedGroup.pvGroupName.toLowerCase()}-welcome-message`,
          pvAccountID: palmettoUserAccount.id,
          pvGroupID: selectedGroup.pvGroupID,
          pvSettingValue: payload.message || '',
          pvEntryDate: new Date(),
        },
      );

      window.dispatchEvent(
        new CustomEvent('show-toast', {
          detail: { message: 'Welcome message updated successfully' },
        }),
      );

      callback && callback();
    } catch (e) {
      console.error('Error updating the message', e);

      window.dispatchEvent(
        new CustomEvent('show-toast', { detail: { message: 'Error saving welcome message' } }),
      );
    }
  };
}

export function saveScannerSetting(payload, callback) {
  return async function (dispatch, getState) {
    dispatch({ type: 'APPLICATION_LOADING_STATE', payload: true });

    const { palmettoUserAccount, selectedGroup, savedScannerSetting } = getState().rootReducer;

    try {
      let authObj = JSON.parse(sessionStorage.getItem('userAuthCreds'));

      await apiClient.put(
        process.env.REACT_APP_PALMETTO_ENDPOINT + '/api/settings?access_token=' + authObj.id,
        {
          pvSettingID: savedScannerSetting.pvSettingID || undefined,
          pvSettingType: `${selectedGroup.pvGroupName.toLowerCase()}-user-selected-scanner`,
          pvAccountID: palmettoUserAccount.id,
          pvGroupID: selectedGroup.pvGroupID,
          pvSettingValue: payload,
          pvEntryDate: new Date(),
        },
      );

      window.dispatchEvent(
        new CustomEvent('show-toast', {
          detail: { message: 'Scanner setting saved successfully' },
        }),
      );

      const room = 'egresseoc-' + payload;
      dispatch({ type: 'SELECTED_SCANNER', payload: payload });
      listenToScanner(room, dispatch, getState);

      callback && callback();
    } catch (e) {
      console.error('Error updating the message', e);
      window.dispatchEvent(
        new CustomEvent('show-toast', { detail: { message: 'Error saving scanner information' } }),
      );
    }

    dispatch({ type: 'APPLICATION_LOADING_STATE', payload: false });
  };
}

export function printReport(payload, callback) {
  return async function (dispatch, getState) {
    const { selectedGroup } = getState().rootReducer;

    let url = process.env.REACT_APP_SERVERLESS_DOCUMENT_API_ENDPOINT;
    // let url = 'https://cloak.ngrok.io'
    let authObj = JSON.parse(sessionStorage.getItem('userAuthCreds'));

    apiClient
      .get(
        `${url}/documents/entryegress/report/${selectedGroup.pvGroupName.toLowerCase()}/${moment(
          payload.start_date,
        ).format('YYYY-MM-DD')}/${moment(payload.end_date).format('YYYY-MM-DD')}`,
        {
          responseType: 'arraybuffer',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          },
        },
      )
      .then((response) => {
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: 'application/vnd.ms-excel' }),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'EntryEgressReport.xlsx'); //or any other extension
        document.body.appendChild(link);
        link.click();
        callback && callback();
      })
      .catch((error) => console.log(error));
  };
}

export function setModeType(payload) {
  return function (dispatch) {
    return dispatch({ type: 'SET_MODE_TYPE', payload: payload.modeType });
  };
}
