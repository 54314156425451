// noinspection JSValidateTypes,JSIgnoredPromiseFromCall

import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { HeaderComponent } from '@zawarski/palmetto-ui-components';
import { MdMoreVert } from 'react-icons/md';
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import ArrowRightOutlinedIcon from '@mui/icons-material/ArrowRightOutlined';
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { LoginIcon, LogoutIcon } from '../assets/icons';
import idCardImg from '../assets/img/id-card.png';
import mentionImg from '../assets/img/mention.png';
import computerLoginImg from '../assets/img/computer-login.png';
import logoImg from '../assets/img/logo.png';
import pcLogo from '../assets/img/pc-logo.png';
import {
  Menu,
  MenuItem,
  IconButton,
  Grid,
  Box,
  Typography,
  TextField,
  Card,
  Collapse,
  List,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Divider,
} from '@mui/material';
import { Loading } from '../../common/Loading';
import ReportDialog from './ReportDialog';
import { convertToHTML } from 'draft-convert';
import { convertFromRaw } from 'draft-js';
import {
  processSignIn,
  processSignOut,
  getLatestGroupSettings,
  processNewUser,
} from '../../redux/actions/index';
import DynamsoftScanner from '../dynamsoft/DynamsoftScanner';
import decodeBarcode from '../utilities/decodeBarcode';
import { processMobileSignin } from '../../redux/actions/index';
import useWindowDimensions, { MOBILE_SIZE } from '../hooks/useWindowDimensions';
import '../styles/common.css';
import '@zawarski/palmetto-ui-components/src/styles/common.css';
import '@zawarski/palmetto-ui-components/src/styles/iron-flex-layout-classes.css';
import useStyles from '../styles/Home.styles';
import { printReport, setModeType } from '../../redux/actions/index';
import { downloadDocument } from '../../redux/actions/documents';

export const ModeTypes = {
  STANDARD: 'Standard',
  STANDARD_NOTES: 'Standard with notes',
  ATTENDANCE: 'Attendance',
  MOBILE: 'Mobile',
};

export default function Home(props) {
  const currentScannedBadge = useSelector((state) => state.rootReducer.currentScannedBadge);
  const welcomeMessageSetting = useSelector((state) => state.rootReducer.welcomeMessageSetting);
  const palmettoUserAccount = useSelector((state) => state.rootReducer.palmettoUserAccount);
  const savedScannerSetting = useSelector((state) => state.rootReducer.savedScannerSetting);
  const selectedScanner = useSelector((state) => state.rootReducer.selectedScanner)
  const modeType = useSelector((state) => state.rootReducer.modeType);

  const [openReportsMenu, setOpenReportsMenu] = useState(false);
  const [openMode, setOpenMode] = useState(false);
  const [printDlgOpen, setPrintDlgOpen] = useState(false);
  const [reportType, setReportType] = useState('');

  const [disableSignIn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedMode, setSelectedMode] = useState(modeType);
  const [localScannedBadge, setLocalScannedBadge] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [notes, setNotes] = useState('');
  const [processing, setProcessing] = useState(false);

  const mobileMode = selectedMode === ModeTypes.MOBILE;
  const open = anchorEl !== null;

  const dispatch = useDispatch();
  const { width } = useWindowDimensions();
  const classes = useStyles();

  const isBadgeEmpty = useMemo(()=> {
    if (loading) return true;
    return Object.keys(currentScannedBadge).length === 0
  }, [currentScannedBadge])

  useEffect(() => {
    const fetchData = () => {
      setLocalScannedBadge({})
      setLoading(true);
      dispatch(
        getLatestGroupSettings(() => {
          setLoading(false);
        }),
      );
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (!mobileMode && selectedMode !== ModeTypes.ATTENDANCE) {
      if (currentScannedBadge && currentScannedBadge.pvBadgeFirstName) {
        currentScannedBadge.pvBadgeFirstName = currentScannedBadge.pvBadgeFirstName?.replace(
          /(\w)(\w*)/g,
          function (g0, g1, g2) {
            return g1.toUpperCase() + g2.toLowerCase();
          },
        );
        setLocalScannedBadge(() => ({...currentScannedBadge}));
      }
    }

    if (selectedMode === ModeTypes.ATTENDANCE) {
      if (currentScannedBadge && Object.keys(currentScannedBadge).length > 0) {
        const payload = Object.assign({}, currentScannedBadge);
        payload.pvStatus = 'SIGN-IN';
        dispatch(processMobileSignin(payload, () => {
          props.history.push('/signin')
        }))
      }
    }
  }, [mobileMode, currentScannedBadge]);

  const logOutUser = () => {
    sessionStorage.removeItem('userAuthCreds');
    window.location.href = '/';
  };

  const manageScanners = () => {
    props.history.push('/managescanners');
  };

  const editMessage = () => {
    props.history.push('/editmessage');
  };

  const toggleMode = (modeType) => {
    setSelectedMode(modeType);
    dispatch(setModeType({ modeType }));
    setAnchorEl(null);
    if (modeType === ModeTypes.MOBILE) props.history.push('/mobile');
  };

  const printReports = (attendance) => {
    setAnchorEl(null);
    setReportType(attendance ? 'attendance' : '');
    setPrintDlgOpen(true);
  };

  const signInFunc = () => {
    if (disableSignIn) return null;
    if (mobileMode) {
      const payload = Object.assign({}, localScannedBadge);
      payload.pvStatus = 'SIGN-IN';
      dispatch(
        processMobileSignin(payload, async () => {
          // *: Change to only toast message Feature #17109
          window.dispatchEvent(new CustomEvent('show-toast', { detail: { message: 'Successfully signed in!' } }));
          props.history.push('/');
        }),
      );
    } else {
      const pvInfo = selectedMode === ModeTypes.STANDARD_NOTES ? notes : null;
      dispatch(
        processSignIn(pvInfo, async () => {
          // *: Change to only toast message Feature #17109
          window.dispatchEvent(new CustomEvent('show-toast', { detail: { message: 'Successfully signed in!' } }));
          props.history.push('/');
        }),
      );
    }
  };

  const signOutFunc = () => {
    if (mobileMode) {
      const payload = Object.assign({}, localScannedBadge);
      payload.pvStatus = 'SIGN-OUT';
      dispatch(
        processMobileSignin(payload, async() => {
          // *: Change to only toast message Feature #17109
          window.dispatchEvent(new CustomEvent('show-toast', { detail: { message: 'Successfully signed out!' } }));
          props.history.push('/');
        }),
      );
    } else {
      const pvInfo = selectedMode === ModeTypes.STANDARD_NOTES ? notes : null;
      dispatch(
        processSignOut(pvInfo, async () => {
          // *: Change to only toast message Feature #17109
          window.dispatchEvent(new CustomEvent('show-toast', { detail: { message: 'Successfully signed out!' } }));
          props.history.push('/');
        }),
      );
    }
  };

  const processNewUserFunc = () => {
    if (mobileMode) {
      setLocalScannedBadge({});
      props.history.push('/');
    } else {
      dispatch(
        processNewUser(() => {
          setLocalScannedBadge({});
          props.history.push('/');
        }),
      );
    }
  };

  function createMarkup() {
    return {
      __html:
        (welcomeMessageSetting &&
          welcomeMessageSetting.pvSettingValue &&
          convertToHTML(convertFromRaw(JSON.parse(welcomeMessageSetting.pvSettingValue)))) ||
        'Welcome to the Emergency Management Division',
    };
  }

  useEffect(() => {
    console.log('badge updated', currentScannedBadge, 'palmettoUserAccount:', palmettoUserAccount);
  }, [currentScannedBadge]);

  const handleUserGuideClick = () => {
    setAnchorEl(null);
    window.open('/EntryEgressUserGuide.pdf');
  };

  const handleBarcodeCallback = (arr) => {
    if (arr[0]) {
      let text = arr[0].barcodeText;
      let badgePayload = decodeBarcode(text);
      setLocalScannedBadge(badgePayload);
    }
  };

  const handleReportCallback = (results) => {
    // results.attendance = reportType === "attendance";
    setProcessing(true);
    if (reportType === 'attendance') {
      let scannerName = savedScannerSetting.pvSettingValue ? savedScannerSetting.pvSettingValue : selectedScanner;
      // noinspection JSUnresolvedReference
      dispatch(
        downloadDocument(
          {
            url: `/documents/entryegress/report/${scannerName}/${results.start_date}`,
            filename: 'entry_egress_attendance_report',
          },
          (err) => {
            if (!err) {
              setProcessing(false);
              setPrintDlgOpen(false);
            }
          },
        ),
      );
    } else {
      dispatch(
        printReport(results, () => {
          setProcessing(false);
          setPrintDlgOpen(false);
        }),
      );
    }
  };

  const headerTitle = (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Typography fontWeight={500} fontSize={20} sx={{ color: '#FFF' }}>
        Entry / Egress
      </Typography>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          backgroundColor: '#1C43B1',
          color: '#FFF',
          ml: 1,
          px: 1,
          py: 1,
          borderRadius: '4px',
        }}>
        <CheckCircleIcon sx={{ mr: 1, height: '12px', width: '12px' }} />
        <Typography sx={{ pr: 1 }}>{selectedMode}</Typography>
      </Box>
    </Box>
  );

  return (
    <div className='layout vertical full-height' style={{ backgroundColor: '#EBEBEB' }}>
      <ReportDialog
        open={printDlgOpen}
        setOpen={setPrintDlgOpen}
        callback={handleReportCallback}
        attendance={reportType === 'attendance'}
        processing={processing}
      />

      <HeaderComponent
        title={headerTitle}
        suffix={
          <IconButton>
            <MdMoreVert className='icon' onClick={(event) => setAnchorEl(event.currentTarget)} />
            <Menu
              sx={{ minWidth: '300px !important' }}
              id='application-menu'
              anchorEl={anchorEl}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              open={open}
              onClose={() => setAnchorEl(null)}>
              <MenuItem
                key='manage-user-guide'
                onClick={handleUserGuideClick}
                sx={{ minWidth: '300px !important' }}>
                <ListItemText>User Guide</ListItemText>
              </MenuItem>
              <MenuItem key='manage-scanners' onClick={manageScanners}>
                <ListItemText>Manage scanners</ListItemText>
              </MenuItem>
              <MenuItem key='edit-message' onClick={editMessage}>
                <ListItemText>Edit message</ListItemText>
                <ListItemIcon>
                  <DriveFileRenameOutlineOutlinedIcon />
                </ListItemIcon>
              </MenuItem>
              <Divider />
              <MenuItem key='reports' onClick={() => setOpenReportsMenu(!openReportsMenu)}>
                <ListItemText>Reports</ListItemText>
                <ListItemIcon>
                  {!openReportsMenu ? <ArrowRightOutlinedIcon /> : <ArrowDropDownOutlinedIcon />}
                </ListItemIcon>
              </MenuItem>
              <Collapse in={openReportsMenu} timeout='auto' unmountOnExit>
                <List component='div' disablePadding sx={{ px: 0.5 }}>
                  <ListItemButton
                    disableTouchRipple
                    sx={{ py: 0 }}
                    onClick={() => printReports(false)}>
                    <ListItemText primary='Print report' />
                  </ListItemButton>
                  <ListItemButton
                    disableTouchRipple
                    sx={{ py: 0 }}
                    onClick={() => printReports(true)}>
                    <ListItemText primary='Print attendance report' />
                  </ListItemButton>
                </List>
              </Collapse>
              <MenuItem key='attendance-mode' onClick={() => setOpenMode(!openMode)}>
                <ListItemText>Mode</ListItemText>
                <ListItemIcon>
                  {!openMode ? <ArrowRightOutlinedIcon /> : <ArrowDropDownOutlinedIcon />}
                </ListItemIcon>
              </MenuItem>
              <Collapse in={openMode} timeout='auto' unmountOnExit>
                <List component='div' disablePadding sx={{ px: 1 }}>
                  <ListItemButton
                    disableTouchRipple
                    sx={{ py: 0 }}
                    onClick={() => toggleMode(ModeTypes.STANDARD)}>
                    <ListItemText primary='Standard mode' />
                    {selectedMode === ModeTypes.STANDARD ? (
                      <ListItemIcon sx={{ justifyContent: 'flex-end', pr: 1 }}>
                        <CheckCircleIcon fontSize='small' sx={{ color: '#2961FD' }} />
                      </ListItemIcon>
                    ) : null}
                  </ListItemButton>
                  <ListItemButton
                    disableTouchRipple
                    sx={{ py: 0 }}
                    onClick={() => toggleMode(ModeTypes.STANDARD_NOTES)}>
                    <ListItemText
                      disableTypography
                      primary={
                        <Typography>
                          Standard mode <span style={{ color: '#767676' }}>with notes</span>
                        </Typography>
                      }
                    />
                    {selectedMode === ModeTypes.STANDARD_NOTES ? (
                      <ListItemIcon sx={{ justifyContent: 'flex-end', pr: 1 }}>
                        <CheckCircleIcon fontSize='small' sx={{ color: '#2961FD' }} />
                      </ListItemIcon>
                    ) : null}
                  </ListItemButton>
                  <ListItemButton
                    disableTouchRipple
                    sx={{ py: 0 }}
                    onClick={() => toggleMode(ModeTypes.ATTENDANCE)}>
                    <ListItemText primary='Attendance mode' />
                    {selectedMode === ModeTypes.ATTENDANCE ? (
                      <ListItemIcon sx={{ justifyContent: 'flex-end', pr: 1 }}>
                        <CheckCircleIcon fontSize='small' sx={{ color: '#2961FD' }} />
                      </ListItemIcon>
                    ) : null}
                  </ListItemButton>
                  <ListItemButton
                    disableTouchRipple
                    sx={{ py: 0 }}
                    onClick={() => toggleMode(ModeTypes.MOBILE)}>
                    <ListItemText primary='Mobile mode' />
                    {selectedMode === ModeTypes.MOBILE ? (
                      <ListItemIcon sx={{ justifyContent: 'flex-end', pr: 1 }}>
                        <CheckCircleIcon fontSize='small' sx={{ color: '#2961FD' }} />
                      </ListItemIcon>
                    ) : null}
                  </ListItemButton>
                </List>
              </Collapse>
              <Divider />
              <MenuItem key='logout' onClick={logOutUser}>
                <ListItemText>Log out</ListItemText>
                <ListItemIcon>
                  <ExitToAppOutlinedIcon />
                </ListItemIcon>
              </MenuItem>
            </Menu>
          </IconButton>
        }
        // Add menu item for admins
      />

      {!!loading && (<Loading text='Loading event, please wait...' />)}

      {isBadgeEmpty && !loading && (<div className="layout vertical center vertical-center flex">
        {mobileMode ? (
          width < MOBILE_SIZE ? (
            <DynamsoftScanner callback={handleBarcodeCallback}/>
          ) : (
            <div style={{width: '500px', height: '500px', margin: 'auto'}}>
              <DynamsoftScanner callback={handleBarcodeCallback}/>
            </div>
          )
        ) : (
          <Box className={classes.dbContainer}>
            <Box className={classes.logoContainer}>
              <img src={selectedMode !== ModeTypes.ATTENDANCE ? logoImg : pcLogo} className={classes.logoStyle}
                   alt="logo"/>
            </Box>
            <span
              className="app-county-title"
              dangerouslySetInnerHTML={createMarkup()}></span>
            <span className="app-county-subtitle">
                    {`Please follow the steps below to start your session`}
                  </span>
            <div className="height-20"></div>
            <Grid container spacing={4}>
              <Grid item xs={selectedMode !== ModeTypes.ATTENDANCE ? 4 : 6}>
                <Card variant="outlined" className={classes.card}>
                  <Box className={classes.imgContainer}>
                    <img src={idCardImg} className={classes.imgStyle} alt="id-card"/>
                  </Box>
                  <Box className={classes.imgTitleWrapper}>
                    <Typography variant={'body1'} fontWeight={700} fontSize={'20px'}>
                      Prepare ID
                    </Typography>
                    <Typography
                      variant={'caption'}
                      color={'#767676'}
                      fontSize={'16px'}
                      sx={{lineHeight: '120%'}}>
                      You can use your <span style={{fontWeight: 700}}>badge</span> or{' '}
                      <span style={{fontWeight: 700}}>driver’s licence</span>
                    </Typography>
                  </Box>
                </Card>
              </Grid>
              <Grid item xs={selectedMode !== ModeTypes.ATTENDANCE ? 4 : 6}>
                <Card variant="outlined" className={classes.card}>
                  <Box className={classes.imgContainer}>
                    <img src={mentionImg} className={classes.imgStyle} alt="mention"/>
                  </Box>
                  <Box className={classes.imgTitleWrapper}>
                    <Typography variant={'body1'} fontWeight={700} fontSize={'20px'}>
                      Scan ID
                    </Typography>
                    <Typography
                      variant={'caption'}
                      color={'#767676'}
                      fontSize={'16px'}
                      sx={{lineHeight: '120%'}}>
                      Please type in any{' '}
                      <span style={{fontWeight: 700}}>additional details</span>, then choose
                      to <span style={{fontWeight: 700}}>sign in</span> or{' '}
                      <span style={{fontWeight: 700}}>sign out</span>
                    </Typography>
                  </Box>
                </Card>
              </Grid>
              {selectedMode !== ModeTypes.ATTENDANCE ? (
                <Grid item xs={4}>
                  <Card variant="outlined" className={classes.card}>
                    <Box className={classes.imgContainer}>
                      <img src={computerLoginImg} className={classes.imgStyle} alt="computer"/>
                    </Box>
                    <Box className={classes.imgTitleWrapper}>
                      <Typography variant={'body1'} fontWeight={700} fontSize={'20px'}>
                        Fill in details
                      </Typography>
                      <Typography
                        variant={'caption'}
                        color={'#767676'}
                        fontSize={'16px'}
                        sx={{lineHeight: '120%'}}>
                        If needed you’ll be asked to fill in additional details
                      </Typography>
                    </Box>
                  </Card>
                </Grid>
              ) : null}

            </Grid>
          </Box>
        )}
      </div>)}

      {!isBadgeEmpty && !loading && (<Box className={classes.signInContainer}>
        <Card variant='outlined' className={classes.signInCard}>
          <Typography variant={'body1'} fontWeight={700} fontSize={32}>
            Hi {localScannedBadge.pvBadgeFirstName}!
          </Typography>
          <Typography variant={'caption'} fontWeight={500} sx={{ color: '#767676' }}>
            Please type in any additional details, then choose an option.
          </Typography>
          {selectedMode && selectedMode === ModeTypes.STANDARD_NOTES ? (
            <TextField
              id='multiline-additional-info'
              className={classes.txtArea}
              variant='filled'
              label='Addiontal Info'
              placeholder={'Please add details here.'}
              size='small'
              multiline
              rows={6}
              maxRows={6}
              value={notes}
              onChange={(ev) => setNotes(ev.target.value)}
            />
          ) : null}
          <Box className={classes.btnContainer}>
            <Box
              className={disableSignIn ? classes.disabledBtn : classes.btnStyle}
              onClick={() => signInFunc()}>
              <Box className={classes.iconContainer}>
                <LoginIcon
                  style={{
                    height: '48px',
                    width: '48px',
                    color: disableSignIn ? '#D8D8D8' : '#6EAA2C',
                  }}
                />
              </Box>
              <Typography
                fontWeight={600}
                fontSize={'18px'}
                sx={{ color: disableSignIn ? '#AEAEAE' : '#1A1A1A' }}>
                SIGN IN
              </Typography>
            </Box>
            <Box className={classes.btnStyle} onClick={signOutFunc}>
              <Box className={classes.iconContainer}>
                <LogoutIcon style={{ height: '48px', width: '48px', color: '#D0021B' }} />
              </Box>
              <Typography fontWeight={600} fontSize={'18px'} sx={{ color: '#1A1A1A' }}>
                SIGN OUT
              </Typography>
            </Box>
          </Box>
          <Box className={classes.footerLink} onClick={processNewUserFunc}>
            <Typography fontWeight={600} sx={{ letterSpacing: '0.5px' }}>
              Not you? Click here!
            </Typography>
          </Box>
        </Card>
      </Box>)}

    </div>
  );
}
