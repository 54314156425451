/**
 * Contains all possible types for actions. This is also provides a single source of truth for all possible actions
 */

// Maps Action names to the variables that they change for the root reducer
export const ALL_TYPES = {
  APPLICATION_LOADING_STATE: 'appLoading', // App Loading State
  SET_LOGIN_STATE: 'loginState', // true if user is logged in
  SET_APP_TYPE: 'appType', // Sets the App Type
  SET_USER_SETTINGS: 'userSettings', // true if user is logged in
  SET_SELECTED_GROUP: 'selectedGroup', // User Selected Group ID
  SET_SELECTED_GROUP_NAME: 'selectedGroupName', // User Selected Group Name
  SET_HEALTH: 'health', // true if the connection is still active and healthy
  PALMETTO_USER_ACCOUNT: 'palmettoUserAccount', // basic user information from palmetto
  CURRENT_SCANNED_BADGE: 'currentScannedBadge', // Information about current scanned badge
  ALL_SCANNERS: 'allScanners', // Information about scanner used
  SELECTED_SCANNER: 'selectedScanner', // current selected scanner name
  WELCOME_MESSAGE_SETTING: 'welcomeMessageSetting', // Saved information about welcome screen message
  SCANNER_SETTING: 'savedScannerSetting', // save scanner setting for the user
  SET_MODE_TYPE: 'modeType', // Sets the Mode Type
};
