import React, { useState, useEffect } from 'react';
import { HeaderComponent, PrimaryButton } from '@zawarski/palmetto-ui-components';
import { PalmettoDatePickerFunc } from './UIComponentsWrappers';
import { MdClear } from 'react-icons/md';
import { IconButton, Dialog } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

import moment from 'moment';

import '../styles/common.css';
import '@zawarski/palmetto-ui-components/src/styles/common.css';
import '@zawarski/palmetto-ui-components/src/styles/iron-flex-layout-classes.css';

export default function ReportDialog({ open, setOpen, callback, attendance, ...props }) {
  const [results, setResults] = useState({});

  const handleDateChange = (date, field) => {
    const dateValue = moment(date).format('YYYY-MM-DD HH:mm:ss');
    setResults({...results, [field]: dateValue})
  };

  const printReportForGroup = () => {
    if (!props.processing) callback(results);
  };

  // Reset the results if the dialog is closed
  useEffect(() => {
    setResults({});
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      classes={{ paper: 'dialog-container' }}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'>
      <div className='layout vertical dialog-container'>
        <HeaderComponent
          icon={
            <IconButton onClick={() => setOpen(false)}>
              <MdClear className='icon' />
            </IconButton>
          }
          title={<span className='truncate'> Print Report </span>}
        />

        <div className='layout vertical flex container-overflow-y dialog-content-padding-adjust'>
          <div className='layout horizontal'>
            <PalmettoDatePickerFunc
              margin='none'
              id='start_date'
              label='Start date'
              placeholder='Enter date'
              value={results.start_date}
              callback={(value) => handleDateChange(value, 'start_date')}
              autoOk={true}
              format='MMM dd, yyyy'
              className='flex'
              invalidDateMessage=''
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              InputProps={{
                readOnly: false,
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>

          <div className='layout horizontal' hidden={attendance}>
            <PalmettoDatePickerFunc
              margin='none'
              id='end_date'
              label='End date'
              placeholder='Enter date'
              value={results.end_date}
              callback={(value) => handleDateChange(value, 'end_date')}
              autoOk={true}
              format='MMM dd, yyyy'
              className='flex'
              invalidDateMessage=''
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              InputProps={{
                readOnly: false,
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>

          <div className='flex'></div>
          <div className='layout horizontal center center-justified'>
            <PrimaryButton setwidth='true' onClick={printReportForGroup}>
              {props.processing ? (
                <div className='layout horizontal center'>
                  <span>Processing request</span>
                  <CircularProgress sx={{ color: '#FFF', ml: '8px' }} size={24} />
                </div>
              ) : (
                `Print`
              )}
            </PrimaryButton>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
