// import DBR from "dynamsoft-javascript-barcode";
// DBR.BarcodeReader.engineResourcePath = "https://cdn.jsdelivr.net/npm/dynamsoft-javascript-barcode@8.1.0/dist/";
// // Please visit https://www.dynamsoft.com/customer/license/trialLicense to get a trial license
// DBR.BarcodeReader.productKeys = "t0076xQAAALDESfavNxts1EL5MfdV7GpmRRQdSR2DTm5eAeQMEmsBO877LbfIlc5SxBk7EHQ1jEo1BIuS550TXkdlWag7R9h8GbYnC9Ipag==";
// // DBR.BarcodeReader._bUseFullFeature = true; // Control of loading min wasm or full wasm.
// export default DBR;
/*
import DBR from "dynamsoft-javascript-barcode";
DBR.BarcodeReader.engineResourcePath = "https://cdn.jsdelivr.net/npm/dynamsoft-javascript-barcode@8.8.3/dist/";
// Please visit https://www.dynamsoft.com/customer/license/trialLicense to get a trial license
DBR.BarcodeReader.productKeys = "t0068NQAAABjHQ8tfNSFpNj0T8h/KkrEfmYK+olGas2CV/xorUG3dUg6/WEL5zwoI6RFQL8vs21SAYSE+Hop9nAFbLYOk6zE=";
// DBR.BarcodeReader._bUseFullFeature = true; // Control of loading min wasm or full wasm.
export default DBR;
*/

import { BarcodeReader} from 'dynamsoft-javascript-barcode';
BarcodeReader.license = 'DLS2eyJoYW5kc2hha2VDb2RlIjoiMTAwMTg2MzA0LTEwMDI2MDYwMSIsIm1haW5TZXJ2ZXJVUkwiOiJodHRwczovL21sdHMuZHluYW1zb2Z0LmNvbS8iLCJvcmdhbml6YXRpb25JRCI6IjEwMDE4NjMwNCIsInN0YW5kYnlTZXJ2ZXJVUkwiOiJodHRwczovL3NsdHMuZHluYW1zb2Z0LmNvbS8iLCJjaGVja0NvZGUiOjE4OTAwODM2MH0=';
BarcodeReader.engineResourcePath = "https://cdn.jsdelivr.net/npm/dynamsoft-javascript-barcode/dist/";
export default BarcodeReader;
