import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => {
  return {
    dbContainer: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: '932px',
      lineHeight: '1.5rem',
    },
    logoContainer: {
      backgroundColor: '#FFF',
      position: 'relative',
      height: '88px',
      width: '88px',
      alignItems: 'center',
      borderRadius: '8px',
      marginBottom: '8px',
    },
    logoStyle: { objectFit: 'contain', height: '100%' },
    card: {
      minHeight: '354px',
      padding: '4px',
      borderRadius: '8px',
      backgroundColor: '#FFF',
    },
    imgContainer: {
      display: 'flex',
      height: '220px',
      padding: '18px 57px 18.047px 57px',
      justifyContent: 'center',
      alignItems: 'center',
      alignSelf: 'stretch',
    },
    imgStyle: { height: '100%', objectFit: 'contain' },
    imgTitleWrapper: {
      display: 'flex',
      padding: '16px',
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: '4px',
      alignSelf: 'stretch',
    },
    signInContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      width: '100%',
    },
    signInCard: {
      display: 'flex',
      flexDirection: 'column',
      width: '539px',
      minHeight: '310px',
      padding: '48px',
      boxShadow: '0px 3px 8px 0px rgba(0, 0, 0, 0.16)',
    },
    btnContainer: {
      marginTop: '32px',
      marginBottom: '24px',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '24px',
    },
    disabledBtn: {
      display: 'flex',
      padding: '32px',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '12px',
      flex: '1 0 0',
      borderRadius: '2px',
      cursor: 'pointer',
      backgroundColor: '#F4F4F4',
    },
    btnStyle: {
      display: 'flex',
      padding: '32px',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '12px',
      flex: '1 0 0',
      cursor: 'pointer',
      borderRadius: '2px',
      backgroundColor: '#F8FAFF',
    },
    iconContainer: {
      height: '58px',
      width: '58px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    footerLink: {
      width: '100%',
      backgroundColor: '#F8FAFF',
      textAlign: 'center',
      cursor: 'pointer',
      padding: '12px 8px',
      textTransform: 'uppercase',
      color: '#2961FD',
    },
    txtAreaContainer: {
      border: '1px solid #707070',
    },
    txtArea: {
      margin: '16px 0',
      fontSize: '16px',
      // border: '2px solid #707070',
      // padding: '12px',
    },
  };
});

export default useStyles;
